import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "headings-subheadings-and-titles"
    }}>{`Headings, Subheadings, and Titles`}</h1>
    <p>{`Keep headings, subheadings, and titles independent from other text that follows. Text beneath these
labels should be in a complete sentence as if the label were not there.`}</p>
    <p>{`Use title case for headings, subheadings, and titles.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Current Benefit Elections`}</li>
        <li parentName="ul">{`Track My Goals`}</li>
        <li parentName="ul">{`Talent and Performance`}</li>
      </ul>
    </Do>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      